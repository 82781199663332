<template>
  <div class="layout-service">

    <!--s: Service Menu-->
    <ServiceMenu v-if="!isPhone()" />
    <!--e: Service Menu-->

    <div class="layout-service-content">
      <div class="service-box">
        <h1 class="title" v-if="!isPhone()"></h1>
        <h1 class="title" v-if="isPhone()">
          {{ $t("footer.accessibility") }}
          <a href="javascript:" class="close" @click="onClose"></a>
        </h1>
        <div class="text3" v-html="$t('footer.accessibility1')"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Accessibility from "./accessibility";
export default Accessibility;
</script>
<style lang="less">
@import "accessibility";
</style>
