/**
 * 可访问性
 * @type {{[]}}
 */
import { isPhone } from "@/utils/tools";
import ServiceMenu from "@/components/ServiceMenu/serviceMenu.vue";
const Accessibility = {
  name: "Accessibility",
  components: {
    ServiceMenu
  },
  data() {
    return {
      isPhone: isPhone
    };
  },
  created() {},
  mounted() {
    document.documentElement.scrollTop = document.body.scrollTop = 0; //定位滚动条到头部
  },
  methods: {
    onClose() {
      history.back();
    }
  }
};
export default Accessibility;
